import React, { useEffect } from "react";
import "../style/brawlPage.css"

export default function BlastJeopardyPage() {

    useEffect(() => {
        
        return;
    }, []);

    return (
        <div>
            <div>{`Not Implemented`}</div>
        </div>
    )
}